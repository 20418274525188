import React from "react";
import "./global.css";

import MyRoutes from "./routes";

function App () {
  return (
    <MyRoutes />
  );
}

export default App;